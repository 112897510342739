/**
 * Base javascript, included on every page
 */

(function($, cookies) {

    // patch jQuery AJAX to make it CSRF-compliant
    // note: uses js-cookie to get cookie values...this is included along
    // with jQuery in the base template

    function csrfSafeMethod(method) {
        // these HTTP methods do not require CSRF protection
        return (/^(GET|HEAD|OPTIONS|TRACE)$/.test(method));
    }

    $.ajaxSetup({
        beforeSend: function(xhr, settings) {
            if (!csrfSafeMethod(settings.type) && !this.crossDomain) {
                xhr.setRequestHeader("X-CSRFToken", cookies.get('csrftoken'));
            }
        }
    });

})(window.jQuery, Cookies);
